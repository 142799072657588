export const PDFToImageConverted = '1A'
export const UserLayoutGenerated = '1B'
export const TextContentExtracted = '1C'
export const ImageContentExtracted = '1D'
export const RawHTMLGenerated = '1E'
export const HTMLLayoutGenerated = '1F'
export const StructuredHTMLGenerated = '1G'
export const MJMLGenerated = '1H'
export const IMAGE_LOAIDING_ERROR = "Failed to convert pdf to image properly, please upload again"
export const EDETAIL_WIDTH = 1024
export const EMAILER_WIDTH = 600
export const APPLICATION_TYPE_EDETAIL= 1;
export const APPLICATION_TYPE_EMAILER= 2;