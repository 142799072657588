import React, {  createContext, useState, useEffect } from 'react';

const FileContext = createContext({
    file : null,
    publishBtnDisabled : true,
    imageFile : null,
    selectFile : () =>{
        
    },
    setImageFile : () =>{

    },
    setPublishButtonDisabled : () =>{

    },
    isLogoutClicked : false
})

export const FileContextProvider = (props) =>{
    const [file, setFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [isPublishDisabled, setPublishDisabled] = useState(null);
    const [isLogoutClicked, setIsLogoutClicked] = useState(null);
    
    useEffect(()=>{
        if(file){
            localStorage.setItem('file', JSON.stringify(file));
        }
        if(imageFile){
            localStorage.setItem('imageFile', JSON.stringify(imageFile));
        }
    },[file, imageFile])

    useEffect(()=>{
        const fileBeforePageRefresh = JSON.parse(window.localStorage.getItem('file'));;
        if(fileBeforePageRefresh){
            setFile(fileBeforePageRefresh);
        }
        const imageFileBeforePageRefresh = JSON.parse(window.localStorage.getItem('imageFile'));;
        if(imageFileBeforePageRefresh){
            setImageFile(imageFileBeforePageRefresh);
        }
    },[])

    const selectFileHandler = (file) =>{
        setFile(file);
    }

    const selectImageFileHandler = (imageFile) =>{
        setImageFile(imageFile);
    }

    const publishBtnHandler = (status) =>{
        setPublishDisabled(status)
    }

    const logoutHandler = () =>{
        setIsLogoutClicked(true);
    }

    return <FileContext.Provider value={{
        file : file, 
        imageFile : imageFile,
        isLogoutClicked : isLogoutClicked,
        selectFile : selectFileHandler, 
        publishBtnDisabled : isPublishDisabled,
        setPublishButtonDisabled : publishBtnHandler,
        selectImageFile : selectImageFileHandler,
        logout : logoutHandler
    }}>{props.children}</FileContext.Provider>
}

export default FileContext;
