import React, { useEffect, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 9999,
    color: "#1c75bb",
  },
}));

const CustomCircularProgressBar = (props) => {
  const classes = useStyles();
  const [isloading, setIsloading] = useState(false);

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <span className="loader"></span>
    </Backdrop>
  );
};

export default CustomCircularProgressBar;
