import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useKeycloak } from "@react-keycloak/web";
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button } from "@mui/material";
import Root from '../Root/Root';
import ErrorPage from '../../Pages/ErrorPage/ErrorPage';
import Dashboard from '../../Pages/Dashboard/Dashboard';
import Header from '../Header/Header';
import FileDetails from '../FileDetails/FileDetails';
import { FileContextProvider } from '../../context/FileContext';

const router = createBrowserRouter([
    { path: '/dynamic-layout', errorElement: <ErrorPage />, element: <Dashboard /> },
    { path: '/dynamic-layout/fileDetails', errorElement: <ErrorPage />, element: <FileDetails /> },
    { path: '/dynamic-layout/AddFiles', errorElement: <ErrorPage />, element: <Root /> },
    // add multiple routes here
    { path: "*", element: <Navigate to="/dynamic-layout" /> }
])

function AppRouter() {
    const { initialized, keycloak } = useKeycloak();
    if (!initialized) {
        return <div className="spinner">
            <Box sx={{ display: 'flex' }}>
                <CircularProgress />
            </Box>
        </div>;
    }

    return (
        <div>
            <FileContextProvider>
                <Header />
                <RouterProvider router={router} />
            </FileContextProvider>
        </div>
    )
}

export default AppRouter