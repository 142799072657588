import React, { memo } from "react";

// const PDFViewer =memo ({ fileURL, isPDFVisible }) => {
//   return (
//     <iframe
//       title="frame"
//       width="100%"
//       height="600px"
//       src={`${fileURL}#view=fitH`}
//       style={{ display: isPDFVisible ? "block" : "none" }}
//     />
//   )
// });

const PDFViewer = memo(({ fileURL, isPDFVisible }) => {

  if(fileURL.includes('/undefined')){
    return null;
  }
  return (
    <div>
      <iframe
        title="frame"
        width="100%"
        height="600px"
        src={`${fileURL}#view=fitH`}
        style={{ display: isPDFVisible ? "block" : "none" }}
      />
    </div>
  );
});

export default PDFViewer;
