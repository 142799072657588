import React, { useState, useEffect, useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import "./UserInfo.scss";
import FileContext from "../../context/FileContext";

function UserInfo() {
  const [userData, setUserData] = useState(null);
  const { initialized, keycloak } = useKeycloak();

  const fileContext = useContext(FileContext);
  
  const getUserInfo = async () => {
    try {
      const userData = await keycloak.loadUserInfo();
      window.localStorage.setItem(
        "userInfo updated ",
        JSON.stringify(userData.given_name)
      );
      setUserData(userData);
      return userData;
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    //console.log("user info added");
    setTimeout(() => {
      getUserInfo();
    }, 100);
  }, []);

  const logout = async () => {
    localStorage.clear();
    fileContext.logout();
    setTimeout(async() =>{
      await keycloak.logout();
    },0)
   
  };

  const toolTipContent = (
    <List
      sx={{ width: "100%" }}
      // component="nav"
      // aria-labelledby="nested-list-subheader"
    >
      <ListItemButton>
        <ListItemText primary={userData?.given_name} />
      </ListItemButton>
      <ListItemButton onClick={logout}>
        <ListItemText color="primary" primary="Logout" />
      </ListItemButton>
    </List>
  );

  return (
    <div className="user-details">
      {/* <span><Tooltip title={toolTipContent}><AccountCircleIcon color='primary' /></Tooltip></span> */}
      {/* <span className='logout' onClick={logout}>{toolTipContent}</span> */}
      <List
        sx={{ width: "100%" }}
        // component="nav"
        // aria-labelledby="nested-list-subheader"
      >
        {/* <ListItemButton>
          <ListItemText primary={userData?.given_name} />
        </ListItemButton> */}
        <ListItemButton onClick={logout}>
          <Tooltip title={"Logout"}>
            <ListItemText color="primary" primary="Logout" />
          </Tooltip>
        </ListItemButton>
      </List>
    </div>
  );
}

export default UserInfo;
