import React, { useState, useEffect } from "react";
import "./Style.scss";
import {
  Box,
  AppBar,
  Toolbar,
  Badge,
  IconButton,
  Avatar,
  Typography,
  Tooltip,
} from "@mui/material";
import { Notifications, Mail } from "@mui/icons-material";
import { useKeycloak } from "@react-keycloak/web";
import { height } from "@mui/system";
import UserInfo from "../UserInfo/UserInfo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function Header() {
  const [userData, setUserData] = useState(null);
  const { initialized, keycloak } = useKeycloak();
  useEffect(() => {
    setTimeout(() => {
      getUserInfo();
    }, 100);
  }, []);
  const getUserInfo = async () => {
    try {
      const userData = await keycloak.loadUserInfo();
      window.localStorage.setItem(
        "userInfo updated ",
        JSON.stringify(userData.given_name)
      );
      setUserData(userData);
      return userData;
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={{ background: "none" }} position="static">
        <Toolbar variant="dense" disableGutters className="toolbar">
          <img src={require("../../Images/Logo.png")} alt="logo" />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="small"
              color="inherit"
              style={{ marginLeft: "16px" }}
            >
              <Tooltip title={"Information"}>
                <img src={require("../../Images/info.png")} alt="logo" />
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              aria-label="show 17 new notifications"
              color="inherit"
              style={{ marginLeft: "16px" }}
            >
              <Badge color="error">
                <Tooltip title={"Notification"}>
                  <img
                    src={require("../../Images/notification.png")}
                    alt="logo"
                  />
                </Tooltip>
              </Badge>
            </IconButton>
            <IconButton
              size="small"
              aria-label="show 17 new notifications"
              color="inherit"
              style={{ marginLeft: "16px" }}
            >
              <Tooltip title={userData?.given_name}>
                <Avatar
                  alt="Remy Sharp"
                  src={require("../../Images/user.jpg")}
                />
              </Tooltip>
              <Tooltip
                title={<UserInfo />}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "lightgray",
                      color: "black",
                    },
                  },
                }}
              >
                {" "}
                <Typography
                  color="black"
                  variant="string"
                  className="user-name"
                >
                  {userData?.given_name}
                </Typography>
              </Tooltip>
              <KeyboardArrowDownIcon
                style={{ color: "black", marginTop: "5px" }}
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
