import React from 'react';
import './style.scss';

function Label(props) {
    return (
       <div>
            <label className='labelText'>{props.text}
                <span style={{color:"red"}}>*</span>
            </label>
       </div>
    )
}

export default Label