import React from 'react';
import Files from '../../components/Files/Files';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';

function Dashboard() {
  return (
    <div> 
        <DashboardHeader />
        <Files />  
    </div>
  )
}

export default Dashboard