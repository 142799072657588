import * as React from "react";
import { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  Paper,
  Button,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import "./Styles.scss";
import { deleteFile, getFileDetails, getListOfPages } from "../../services/api";
import FileContext from "../../context/FileContext";
import { FileStatus } from "./FilesStatusEnum";
import DeletePrompt from "../prompts/DeletePrompt";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledInputBase, Search, SearchIconWrapper } from "./StyledInput";
import {
  FILE_STATUS_COMPLETED,
  FILE_STATUS_CREATED,
  FILE_STATUS_DELETED,
  FILE_STATUS_INPROGRESS,
  FILE_STATUS_FAILED,
} from "./constant";
import { FILE_DETAILS_DASHBOARD } from "../ImageLayout/dummy-response";

interface FilesProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function Files(props: FilesProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function CustomPaginationActionsTable() {
  const [page, setPage] = React.useState(0);
  const [files, setFiles] = React.useState([]);
  const [filteredFiles, setFilteredFiles] = React.useState([]);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const fileContext = useContext(FileContext);
  const [showDelete, setShowDelete] = React.useState(false);
  const [fileTobeDeleted, setFileTobeDeleted] = React.useState("");
  const [searchText, setSearchText] = React.useState("");
  const [totalFiles, setTotalFiles] = React.useState("");
  const [isDataLoading, setIsDataLoading] = React.useState(false);
  const navigate = useNavigate();
  const toastId = React.useRef(null);

  const getAllPages = () => {
    let filesLimitToGet = rowsPerPage * (page + 1);

    // when user selects ALL option from dropdown then rowsPerPage value will be -1. So here we need to get all files
    if (filesLimitToGet === -1) {
      filesLimitToGet = totalFiles;
    }
    if (fileContext?.file) {
      const body = {
        conversionId: fileContext?.file?.id,
        uuid: "c99b56ac-da8c-4569-8998-5d02704c2fa8",
        searchText: "",
        pageNumber: 0,
        limit: filesLimitToGet,
      };
      setIsDataLoading(true);
      getListOfPages(body)
        .then((res) => {
          setIsDataLoading(false);
          let files = res?.data?.items || [];
          setTotalFiles(fileContext?.file?.num_pages);
          files.forEach(
            (file, index) =>
              (files[index].fileName =
                file.image_path
                  ?.split("/")
                  .pop()
                  .split(".")
                  .slice(0, -1)
                  .join(".")
                  .split(".")
                  .slice(0, -1)
                  .join(".") +
                "_" +
                (rowsPerPage * 0 + index + 1))
          );
          setFiles(files);
          setFilteredFiles(files);
          if (!files.length) {
            if (!toast.isActive(toastId.current)) {
              toast.error("Sorry there are no files", {
                toastId: "files-search-error",
                pauseOnFocusLoss: false,
              });
            }
          }
        })
        .catch((err) => {
          setIsDataLoading(false);
        });
    }
  };

  React.useEffect(() => {
    getAllPages();
  }, [page, rowsPerPage]);

  React.useEffect(() => {
    if (fileContext.file) {
      getAllPages();
    }
  }, [fileContext.file]);

  React.useEffect(() => {
    if (fileContext.isLogoutClicked) {
      navigate("/dynamic-layout");
    }
  }, [fileContext.isLogoutClicked]);

  const convertDateFormat = (timestamp) => {
    const DATE = new Date(timestamp);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // here we will format the date in the way we want
    const date =
      DATE.getDate() + " " + months[DATE.getMonth()] + " " + DATE.getFullYear();

    // here we will format the time in the way we want

    let hours = DATE.getHours();
    let minutes = DATE.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return date + " | " + strTime;
  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - files.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      width: "100vW",
      fontWeight: "600",
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      background: "#fafafa 0% 0% no-repeat padding-box",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const viewFile = (file) => {
    if (file) {
      setIsDataLoading(true);
      getFileDetails(file.id, file.conversion_id)
        .then((res) => {
          setIsDataLoading(false);
          let fileDetails = res?.data || {};
          fileContext.selectFile(fileDetails);
          navigate("/dynamic-layout/AddFiles");
        })
        .catch((err) => {
          setIsDataLoading(false);
          console.log(err);
        });
    }
  };

  const checkIsDisabled = (file) => {
    let isDisabled = false;
    if (file.status === 0 && !file.html_file_path) {
      isDisabled = true;
    } else if (file.status === 4 && !file.html_file_path) {
      isDisabled = true;
    }
    return isDisabled;
  };

  if (isDataLoading)
    return (
      <div className="progress-bar">
        <CircularProgress />
      </div>
    );

  return (
    <div className="myFilesContainer">
      {/* <ToastContainer /> */}
      <Box className="dashboard-header">
        <span>Page Details</span>
        <button onClick={() => navigate("/")} className="addBtn">
          Go To Dashboard
        </button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "15px",
        }}
      >
        <span className="myFiles">{`Total Pages(${totalFiles})`}</span>
        <Box>
          <form>
            <Search>
              <div className="search-icon">
                <SearchIconWrapper>
                  <SearchIcon style={{ fill: "#034EA2" }} />
                </SearchIconWrapper>
              </div>
              <StyledInputBase
                placeholder="Search by Page name"
                inputProps={{ "aria-label": "search" }}
                variant="outlined"
                className="text"
                value={searchText}
                onChange={(e) => {
                  setFilteredFiles(
                    files.filter((file) =>
                      file.fileName.includes(e.target.value)
                    )
                  );
                  setSearchText(e.target.value);
                }}
              />
            </Search>
          </form>
        </Box>
      </Box>

      {
        filteredFiles.length ? (
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500, maxHeight: 440 }}
              stickyHeader
              aria-label="custom pagination table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">
                    <span className="TableBodyText title">Page Name</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText title">Uploaded on</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText title">Last updated</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText title">Status</span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <span className="TableBodyText title">Action</span>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? filteredFiles.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : filteredFiles
                ).map((file, index) => (
                  <StyledTableRow key={file.id}>
                    <StyledTableCell align="left">
                      <span className="TableBodyText">{file.fileName}</span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="TableBodyText">
                        {convertDateFormat(file.createdAt)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className="TableBodyText">
                        {convertDateFormat(file.updatedAt)}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <span className={`status status-${file.status}`}>
                        {FileStatus[file.status]}{" "}
                      </span>
                      {file.status == 1 ? (
                        <span className="status-info">
                          <Tooltip title={file?.message?.innerStatus?.message}>
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="primary"
                            />
                          </Tooltip>
                        </span>
                      ) : null}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <button
                        disabled={checkIsDisabled(file)}
                        onClick={() => viewFile(file)}
                        className={`Actionbtn ${
                          checkIsDisabled(file) ? "disabled" : ""
                        }`}
                      >
                        View
                      </button>
                      {/* <button onClick={() => {
                                            setFileTobeDeleted(file);
                                            setShowDelete(true);
                                        }} className='Actionbtn'>Delete</button> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow align="left">
                  <TablePagination
                    align="left"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    count={filteredFiles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "Items per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={Files}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <></>
        )
        // <div className="no-files-container">
        //     <Box>
        //         <img src={require('../../Images/no-files.png')} alt="logo" />
        //         <span className="noFileText">No files added yet!</span>
        //         <span onClick={goToAddFiles} className="addFileBtn">+ Add Files</span>
        //     </Box>
        // </div>
      }
    </div>
  );
}
