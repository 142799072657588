import React, { useState, useEffect, useCallback } from "react";
import "./style.scss";
import { Button } from "@mui/material";
import Label from "./Label";
import { styled } from "@mui/material/styles";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PDFViewer from "../PDFViewer/PDFViewer";
import { validateFileName } from "../../services/api";
import { toast } from "react-toastify";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, InputLabel, Select, IconButton, MenuItem } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { APPLICATION_TYPES } from "./constant";

function FileUpload(props) {
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [fileDescription, setFileDescription] = useState("");
  const [isValidFileName, setIsValidFileName] = useState(true);
  const [fileBlobURL, setFileBlobURL] = useState(false);
  const [applicationType, setApplicationType] = useState("");


  const validateFileUpload = () => {
    if (fileName.trim() && fileDescription.trim() && applicationType && file) {
      const fileDetails = {
        fileName: fileName,
        fileDescription: fileDescription,
        file: file,
        applicationType: applicationType.id,
        isValidFileName: isValidFileName,
      };
      props.onFileUpload(fileDetails);
    } else {
      const fileDetails = {
        isValidFileName: false,
      };
      props.onFileUpload(fileDetails);
    }
  };

  useEffect(() => {
    validateFileUpload();
  }, [file, fileName, fileDescription, isValidFileName, applicationType]);

  useEffect(() => {
    const body = {
      name: fileName,
    };
    const getData = setTimeout(() => {
      if (fileName) {
        validateFileName(body)
          .then((response) => {
            // console.log(response.data[0]);
            const isFileNameValid = !response?.data?.isNameExists;
            setIsValidFileName(isFileNameValid);
          })
          .catch((err) => { });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [fileName]);

  useEffect(() => {
    const dropContainer = document.getElementById('dropContainer')
    dropContainer.ondragover = dropContainer.ondragenter = function (evt) {
      evt.preventDefault();
    };

    dropContainer.ondrop = function (event) {
      event.preventDefault();
      if (event.dataTransfer.files) {
        setFile(event.dataTransfer.files[0]);
        setFileBlobURL(URL.createObjectURL(event.dataTransfer.files[0]));
      }

    };
  }, [])

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const onFileChange = (event) => {
    event.preventDefault();
    if (event.target.files) {
      if (event.target.files[0].type === "application/pdf") {
        setFile(event.target.files[0]);
        setFileBlobURL(URL.createObjectURL(event.target.files[0]));
      } else {
        toast.error("Please select pdf file only!");
      }
    }
  };
  return (
    <div className="fileUploadContainer">
      <div className="uploadFile">
        <div className="addFile">Add File</div>
        <div className="fileDetails">
          {/* <div className="fieldDiv">
            <Label text="Type" />
            <FormControl className="upload-file-dropdown">
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={applicationType}
                onChange={(e) => setApplicationType(e.target.value)}
                variant="outlined"
                sx={{ "& .MuiSelect-iconOutlined": { display: applicationType ? 'none' : '' }, "&.Mui-focused .MuiIconButton-root": { color: 'primary.main' } }}
                endAdornment={<IconButton sx={{ visibility: applicationType ? "visible" : "hidden", position: 'absolute', right: 0 }} onClick={() => setApplicationType('')}><ClearIcon fontSize="small" /></IconButton>}
              >

                <MenuItem key={1} value={'Edetail'}>
                  Edetail
                </MenuItem>
                <MenuItem key={2} value={'Emailer'}>
                  Emailer
                </MenuItem>

              </Select>
            </FormControl>
          </div> */}
          <div className="fieldDiv">
            <FormControl>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                {APPLICATION_TYPES.map(application =>{
                  return (<FormControlLabel value={application.id} onClick={(e) => setApplicationType(application)} control={<Radio />} label={application.label} />)
                })}
                
                {/* <FormControlLabel value="Emailer" onClick={(e) => setApplicationType(e.target.value)} control={<Radio />} label="Emailer" /> */}
              </RadioGroup>
            </FormControl>
          </div>

          <div className="fieldDiv">
            <div>
              <span>Title</span>
              <span className="required">*</span>{" "}
              {!isValidFileName ? (
                <>
                  <span className="error">This title already exists</span>
                </>
              ) : null}
            </div>
            <input
              className={`inputField ${!isValidFileName ? "inputError" : ""}`}
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              placeholder="Request title"
            />
          </div>

          <div className="fieldDiv">
            <Label text="Description" />
            <textarea
              className="inputField"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
              placeholder="Add a description"
            />
          </div>
          {!file ? (
            <div id="dropContainer" className="fieldDiv">
              <Label text="Upload File" />
              <Button component="label">
                <div className="uploadFileDiv">
                  <img
                    className="uploadImg"
                    src={require("../../Images/fileUpload.png")}
                    alt="logo"
                  />
                  <div className="dragNDropText">
                    Drag and drop to upload or
                    <span
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        color: "#034EA2",
                      }}
                    >
                      {" "}
                      Browse{" "}
                    </span>
                    to choose a file
                  </div>
                  <div className="labelText">
                    File type: PDF; Max file size : 50 MB
                  </div>
                </div>
                <VisuallyHiddenInput
                  accept="application/pdf"
                  onChange={onFileChange}
                  type="file"
                />
              </Button>
            </div>
          ) : (
            <div>
              <div className="fileName">Attached File (1)</div>
              <div className="attachedFileBox">
                <div>
                  <img src={require("../../Images/pdf-icon.png")} alt="pdfIcon" />
                  <span className="selectedFile">{file?.name}</span>
                </div>
                <Button component="label" className="ReplaceBtn">
                  <span>Replace</span>
                  <VisuallyHiddenInput
                    accept="application/pdf"
                    onChange={onFileChange}
                    type="file"
                  />
                </Button>
              </div>
            </div>
          )}
          {/* <div className='fileName'> {file?.name}</div> */}
        </div>
      </div>
      {!file ? (
        <div className="previewDiv">
          <DescriptionOutlinedIcon
            style={{ fontSize: "50px", color: "#707070" }}
          />
          <div className="dragNDropText">Upload a file to get an preview</div>
        </div>
      ) : (
        <div className="pdf-viewer">
          <PDFViewer fileURL={fileBlobURL} isPDFVisible={true} />
        </div>
      )}
    </div>
  );
}

export default FileUpload;
