export const styleManager = {
    appendTo: "#stylesMangerBlock",
    custom: true,
    sectors: [
      {
        name: "Dimension",
        open: false,
        buildProps: [
          "width",
          "flex-width",
          "height",
          "max-width",
          "min-height",
          "margin",
          "padding",
          //"color",
        ],
        properties: [
          {
            id: "flex-width",
            type: "integer",
            name: "Width",
            units: ["px", "%"],
            property: "flex-basis",
            toRequire: 1,
          },
          // { properties: [{ name: "Font color", property: "color" }] },
          {
            property: "margin",
            properties: [
              { name: "Top", property: "margin-top" },
              { name: "Right", property: "margin-right" },
              { name: "Bottom", property: "margin-bottom" },
              { name: "Left", property: "margin-left" },
            ],
          },
          {
            property: "padding",
            properties: [
              { name: "Top", property: "padding-top" },
              { name: "Right", property: "padding-right" },
              { name: "Bottom", property: "padding-bottom" },
              { name: "Left", property: "padding-left" },
            ],
          },
        ],
      },
      {
        name: "Typography",
        open: false,
        buildProps: [
          "font-family",
          "font-size",
          "font-weight",
          "letter-spacing",
          "color",
          "line-height",
          "text-align",
          "text-decoration",
          "text-shadow",
        ],
        properties: [
          {
            property: "font-family",
            name: "Font family",
            // type: "select"
            default: "'Calibri', sans-serif",
          },
          { name: "Weight", property: "font-weight" },
          { name: "Font color", property: "color", default: "" },
   
          {
            property: "font-size",
            type: "number",
            default: "12",
            name: "Font size",
            units: ["px", "%", "em", "rem", "vh", "vw"],
            min: 0,
          },
          {
            property: "letter-spacing",
            type: "number",
            default: "0",
            name: "Letter spacing",
            units: ["px", "%", "em", "rem", "vh", "vw"],
            min: 0,
          },
          {
            property: "text-align",
            type: "radio",
            defaults: "left",
            list: [
              { value: "left", name: "Left", className: "fa fa-align-left" },
              {
                value: "center",
                name: "Center",
                className: "fa fa-align-center",
              },
              { value: "right", name: "Right", className: "fa fa-align-right" },
              {
                value: "justify",
                name: "Justify",
                className: "fa fa-align-justify",
              },
            ],
          },
          {
            property: "text-decoration",
            type: "radio",
            defaults: "none",
            list: [
              { value: "none", name: "None", className: "fa fa-times" },
              {
                value: "underline",
                name: "underline",
                className: "fa fa-underline",
              },
              {
                value: "line-through",
                name: "Line-through",
                className: "fa fa-strikethrough",
              },
            ],
          },
          {
            property: "text-shadow",
            properties: [
              { name: "X position", property: "text-shadow-h" },
              { name: "Y position", property: "text-shadow-v" },
              { name: "Blur", property: "text-shadow-blur" },
              { name: "Color", property: "text-shadow-color" },
            ],
          },
        ],
      },
      {
        name: "Decorations",
        open: false,
        buildProps: [
          "opacity",
          "border-radius",
          "border",
          "box-shadow",
          "background",
        ],
        properties: [
          {
            property: "background-color",
            default: "none",
            type: "color",
            name: "background-color",
          },
        ],
      },
      // {
      //   name: "Animations",
      //   open: false,
      //   buildProps: ["transition", "perspective", "transform"],
      // },
      // {
      //   name: "Settings",
      //   buildProps: ["src", "provider"],
      //   properties: [
      //     {
      //       name: "Source",
      //       property: "src",
      //       type: "text",
      //       defaults: "eg. ./media/video.mp4",
   
      //   //       // list: [{ value: "src", className: "fa fa-times" }],
      //   //     },
      //   //     {
      //   //       name: "Provider",
      //   //       property: "provider",
      //   //       type: "select",
      //   //       defaults: "html5",
      //   //       list: [
      //   //         { value: "html5", name: "HTML5" },
      //   //         { value: "youtube", name: "Youtube" },
      //   //         { value: "vimeo", name: "Vimeo" },
      //   //       ],
      //   //       // list: [{ value: "src", className: "fa fa-times" }],
      //   //     },
      //   //     {
      //   //       name: "Poster",
      //   //       property: "poster",
      //   //       type: "text",
      //   //       defaults: "eg. ./media/image",
   
      //   //       // list: [{ value: "src", className: "fa fa-times" }],
      //   //     },
      //   //   ],
      //   // },
      //   {
      //     name: "Extra",
      //     open: false,
      //     buildProps: ["transition", "perspective", "transform"],
      //     properties: [
      //       {
      //         property: "transition",
      //         properties: [
      //           { name: "Property", property: "transition-property" },
      //           { name: "Duration", property: "transition-duration" },
      //           { name: "Easing", property: "transition-timing-function" },
      //         ],
      //       },
      //       {
      //         property: "transform",
      //         properties: [
      //           { name: "Rotate X", property: "transform-rotate-x" },
      //           { name: "Rotate Y", property: "transform-rotate-y" },
      //           { name: "Rotate Z", property: "transform-rotate-z" },
      //           { name: "Scale X", property: "transform-scale-x" },
      //           { name: "Scale Y", property: "transform-scale-y" },
      //           { name: "Scale Z", property: "transform-scale-z" },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     name: "Flex",
      //     open: false,
      //     properties: [
      //       {
      //         name: "Flex Container",
      //         property: "display",
      //         type: "select",
      //         defaults: "block",
      //         list: [
      //           { value: "block", name: "Disable" },
      //           { value: "flex", name: "Enable" },
      //         ],
      //       },
      //       {
      //         name: "Flex Parent",
      //         property: "label-parent-flex",
      //         type: "integer",
      //       },
      //       {
      //         name: "Direction",
      //         property: "flex-direction",
      //         type: "radio",
      //         defaults: "row",
      //         list: [
      //           {
      //             value: "row",
      //             name: "Row",
      //             className: "icons-flex icon-dir-row",
      //             title: "Row",
      //           },
      //           {
      //             value: "row-reverse",
      //             name: "Row reverse",
      //             className: "icons-flex icon-dir-row-rev",
      //             title: "Row reverse",
      //           },
      //           {
      //             value: "column",
      //             name: "Column",
      //             title: "Column",
      //             className: "icons-flex icon-dir-col",
      //           },
      //           {
      //             value: "column-reverse",
      //             name: "Column reverse",
      //             title: "Column reverse",
      //             className: "icons-flex icon-dir-col-rev",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Justify",
      //         property: "justify-content",
      //         type: "radio",
      //         defaults: "flex-start",
      //         list: [
      //           {
      //             value: "flex-start",
      //             className: "icons-flex icon-just-start",
      //             title: "Start",
      //           },
      //           {
      //             value: "flex-end",
      //             title: "End",
      //             className: "icons-flex icon-just-end",
      //           },
      //           {
      //             value: "space-between",
      //             title: "Space between",
      //             className: "icons-flex icon-just-sp-bet",
      //           },
      //           {
      //             value: "space-around",
      //             title: "Space around",
      //             className: "icons-flex icon-just-sp-ar",
      //           },
      //           {
      //             value: "center",
      //             title: "Center",
      //             className: "icons-flex icon-just-sp-cent",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Align",
      //         property: "align-items",
      //         type: "radio",
      //         defaults: "center",
      //         list: [
      //           {
      //             value: "flex-start",
      //             title: "Start",
      //             className: "icons-flex icon-al-start",
      //           },
      //           {
      //             value: "flex-end",
      //             title: "End",
      //             className: "icons-flex icon-al-end",
      //           },
      //           {
      //             value: "stretch",
      //             title: "Stretch",
      //             className: "icons-flex icon-al-str",
      //           },
      //           {
      //             value: "center",
      //             title: "Center",
      //             className: "icons-flex icon-al-center",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Flex Children",
      //         property: "label-parent-flex",
      //         type: "integer",
      //       },
      //       {
      //         name: "Order",
      //         property: "order",
      //         type: "integer",
      //         defaults: 0,
      //         min: 0,
      //       },
      //       {
      //         name: "Flex",
      //         property: "flex",
      //         type: "composite",
      //         properties: [
      //           {
      //             name: "Grow",
      //             property: "flex-grow",
      //             type: "integer",
      //             defaults: 0,
      //             min: 0,
      //           },
      //           {
      //             name: "Shrink",
      //             property: "flex-shrink",
      //             type: "integer",
      //             defaults: 0,
      //             min: 0,
      //           },
      //           {
      //             name: "Basis",
      //             property: "flex-basis",
      //             type: "integer",
      //             units: ["px", "%", ""],
      //             unit: "",
      //             defaults: "auto",
      //           },
      //         ],
      //       },
      //       {
      //         name: "Align",
      //         property: "align-self",
      //         type: "radio",
      //         defaults: "auto",
      //         list: [
      //           {
      //             value: "auto",
      //             name: "Auto",
      //           },
      //           {
      //             value: "flex-start",
      //             title: "Start",
      //             className: "icons-flex icon-al-start",
      //           },
      //           {
      //             value: "flex-end",
      //             title: "End",
      //             className: "icons-flex icon-al-end",
      //           },
      //           {
      //             value: "stretch",
      //             title: "Stretch",
      //             className: "icons-flex icon-al-str",
      //           },
      //           {
      //             value: "center",
      //             title: "Center",
      //             className: "icons-flex icon-al-center",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //
    ],
  };
  