export const FileStatus = {
    0 : "Created",
    1 : "In Progress",
    2 : "Published",
    3 : "Deleted",
    4 : "Failed"
}

export const APPLICATION_TYPES = {
    1 : "Edetail",
    2 : "Emailer"
}