import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelPrompt from "../prompts/CancelPrompt";
import Dropdown from "../Dropdown/Dropdown";

import "./style.scss";
import FileContext from "../../context/FileContext";
import { STEPPER_STEP_UPLOADFILE } from "../Root/constants";
import Tooltip from "@mui/material/Tooltip";

function ButtonHeader(props) {
  const [open, setOpen] = useState(false);
  const [cancelAction, setCancelAction] = useState("");
  const navigate = useNavigate();

  const fileContext = useContext(FileContext);

  const checkIsDisabled = () => {
    if (props.currentStep === 0) {
      return !props?.isValidFileName || props.isProcessing;
    } else if (props.currentStep === 1) {
      return !props.isImageLayoutGenerated || !props.croppingBoxes.length;
      // return false;
    } else if (props.currentStep === 2) {
      return !props.isLayoutGenerated || props.disableGenerateLayout;
    } else {
      return false;
    }
  };

  const goBack = (to) => {
    setOpen(true);
    setCancelAction(to);
  };
  const getPDFname = () => {
    return props?.fileDetails?.pdf_file_path.split("/").pop();
  };
  const getUserDefinedFileName = () => {
    return props.fileDetails?.name
      ? ` / FileName: ${props.fileDetails?.name}` //handled When filename is not available
      : null;
  };

  return (
    <>
      <Box className="dashboard-header">
        <div
          onClick={() =>
            goBack(
              `${
                props.currentStep == STEPPER_STEP_UPLOADFILE
                  ? "dashboard"
                  : "fileDetails"
              }`
            )
          }
          className="dashboardTitle"
        >
          <ArrowBackIcon style={{ fill: "#034EA2", marginRight: "8px" }} />
          Dashboard
          <Tooltip title={getPDFname()}>
            <span>{getUserDefinedFileName()}</span>
          </Tooltip>
        </div>
        {/*<div className="dashboardTitle">{props?.fileDetails?.name}</div>*/}
        <div className="action-buttons">
          <button onClick={() => goBack("dashboard")} className="cancelBtn">
            CANCEL
          </button>
          {props.currentStep > 0 ? (
            <button
              disabled={
                props.isBackDisabled ||
                (props.currentStep === 3 && !props.fileDetails) ||
                props.isPublished
              }
              onClick={() => {
                goBack("previous page");
              }}
              className="backBtn"
            >
              BACK
            </button>
          ) : null}
          {props.currentStep !== 3 ? (
            <button
              disabled={checkIsDisabled() || props.isPublished}
              onClick={props.nextClick}
              className="nextBtn"
            >
              NEXT
            </button>
          ) : (
            <Dropdown
              exportClicked={props.exportClicked}
              exportResponsiveHTML={props.exportResponsiveHTML}
              disable={fileContext.publishBtnDisabled || !props.fileDetails}
              downloadClicked={props.downloadClicked}
              downloadResponsiveHTML={props.downloadResponsiveHTML}
              setIsDownloadZip={props.setIsDownloadZip}
              applicationType={props.fileDetails?.application_type}
              fileContext={fileContext}
              fileDetails={props.fileDetails}
            />
          )}
        </div>
      </Box>
      {open && (
        <CancelPrompt
          open={open}
          setOpen={setOpen}
          cancelAction={cancelAction}
          handleBack={props.handleBack}
          activeStep={props.currentStep}
          cancelHandler={props.modifyImageCancelHandler}
          setIsGJSLoading={props.setIsGJSLoading}
        />
      )}
    </>
  );
}

export default ButtonHeader;
