import React, { useState } from 'react';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "./services/keycloak";
import AppRouter from './components/AppRouter/AppRouter';
import { ToastContainer } from "react-toastify";
import './App.scss'

function App() {

    const [userData, setUserData] = useState(null);
    const tokenLogger = (tokenData) => {
        if (tokenData.token) {
            window.localStorage.setItem("userToken", tokenData.token);
            window.localStorage.setItem("userInfo", getUserInfo());
        }
    };
    const getUserInfo = async () => {
        try {
            const userData = await keycloak.loadUserInfo();
            setUserData(userData.sub);
        } catch (error) {
            console.log(error);
        }
    };

    const eventLogger = (event) => { };
    return (
        <div className='App'>
            <ToastContainer />
            <ReactKeycloakProvider
                initOptions={{ onLoad: "login-required" }}
                authClient={keycloak}
                onEvent={eventLogger}
                onTokens={tokenLogger}
            > 
                <AppRouter />
            </ReactKeycloakProvider> 
        </div>
    )
}

export default App