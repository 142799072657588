import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import "./Styles.scss";

function DashboardHeader() {
  const navigate = useNavigate();
  const goToAddFiles = () => {
    navigate("/dynamic-layout/AddFiles");
  };
  React.useEffect(() => {
    document.body.style.overflowY = "scroll";
  }, []);
  return (
    <Box className="dashboard-header">
      <span>Dashboard</span>
      <button onClick={goToAddFiles} className="addBtn">
        + Add Files
      </button>
    </Box>
  );
}

export default DashboardHeader;
