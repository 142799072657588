import { Link } from "react-router-dom";
import "./ErrorPage.scss";

function ErrorPage() {
  return (
    <div className="error-page">
      This is not a valid URL Please click on <Link to="/">here</Link> to go to
      home page
    </div>
  );
}

export default ErrorPage;
