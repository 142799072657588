export const tableHtml = (table, cssDesktop, cssMedia) => {
  table = table.replace(/&nbsp;/g, " ");
  table = table.replace(/position:/g, "");
  table = table.replace(/absolute;/g, "");
  table = table.replace(/relative;/g, "");
  table = table.replace(/undefined/g, "");
  table = table.replace(/<tr>\s*<td><\/td>\s*<\/tr>/g, ""); //Remove empty <tr><td></td></tr> from HTML string

  //table = table.replace(/div/g, "span");
  return `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd">
    <html
       lang="en"
       xmlns="http://www.w3.org/1999/xhtml"
       xmlns:v="urn:schemas-microsoft-com:vml"
       xmlns:o="urn:schemas-microsoft-com:office:office"
       xmlns:w="urn:schemas-microsoft-com:office:word"
       >
       <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=9" />
          <meta content="telephone=no" name="format-detection"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <title></title>
          <!--[if gte mso 9]>
          <xml>
             <o:OfficeDocumentSettings>
                <o:AllowPNG />
                <o:PixelsPerInch>96</o:PixelsPerInch>
             </o:OfficeDocumentSettings>
          </xml>
          <![endif]-->
          <!--[if gte mso 9]>
          <style>
             sup {
             font-size: 100% !important;
             }
          </style>
          <![endif]-->
          <style>
             html {
             -webkit-text-size-adjust: none !important;
             -ms-text-size-adjust: none !important;
             }
             body {
             width: 100%;
             background-color: #ffffff;
             margin: 0;
             padding: 0;
           
             -webkit-font-smoothing: antialiased;
             font-family:Arial;
             }
             table {
             border-collapse: collapse;
             }
             ${cssDesktop}
             @media only screen and (min-width: 300px) and (max-width: 600px) {
                ${cssMedia}

               .makeCol100percent{
                  width:100% !important;
                  height:auto!important;
               }
               .makeImage100percent{
                  width:100%;
               }
               .makeText100percent{
                  width:100%;
                  padding-left:15px;
                  padding-right:15px;
               }
             .stackColumn {
           display: block !important;
            /* display: flex;
             justify-content: center;
             align-items: center;
             flex-direction: column;*/
             }
             .resp-img{
                 width:100% !important;
             }
             .verticalGap{
             display:none}
             .stackImage{
             text-align :center;
             }
             .stackText{
             text-align :center;
             }
             .singleColumnLeftGap{
             }
             td{
             font-family: sans-serif;
            /* font-weight: bold;*/
             font-style: normal;
             color: #5d5d5d;
            /* font-size: 18px !important;
             line-height:20px;*/
             }
             }
             @media only screen and (min-width: 641px) and (max-width: 769px) {
             td{
             font-family: sans-serif;
             /* font-weight: bold;*/
             font-style: normal;
             color: #5d5d5d;
           /*  font-size: 18px !important;
             line-height:20px;*/
             }
             }
             @media only screen and (max-width: 600px) {
             .resp-table{
             width:100% !important;
             }
             }
          </style>
       </head>
       <body>
          <table
             width="100%"
             border="0"
             cellspacing="0"
             cellpadding="0"
             bgcolor="#f8f7f7;"
             style="background-color: #f8f7f7"
             class="deviceWidth"
             >
          <tbody>
             <tr>
                <td
                   align="center"
                   valign="top"
                   style="background-color: #f8f7f7"
                   bgcolor="#f8f7f7;"
                   >
                   ${table}
                </td>
               </tr>
            </tbody>
         </table>
       </body>
    </html>`;
};
