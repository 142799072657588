import React, { useStae, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import FileContext from "../../context/FileContext";
import "./Prompt.scss";
import { STEPPER_STEP_UPLOADFILE } from "../Root/constants";

export default function CancelPrompt(props) {
  const fileContext = useContext(FileContext);
  const navigate = useNavigate();

  const handleClose = (yes) => {
    if (yes === "yes") {
      if (props.activeStep === 1) {
        props.cancelHandler();
      }
      // fileContext.selectFile(null);
      // localStorage.removeItem("activeStep");
      // localStorage.removeItem("file");
      if (props.cancelAction === "previous page") {
        props.setIsGJSLoading(true);
        setTimeout(() => {
          props.handleBack();
          // props.setIsGJSLoading(false);
        }, 500);
      } else if (props.cancelAction === "fileDetails"){
        if (props.activeStep === STEPPER_STEP_UPLOADFILE) {
          navigate("/dynamic-layout");
        }else{
          navigate('/dynamic-layout/fileDetails');
        }
        
      } else {
        navigate("/dynamic-layout");
      }
    }
    props.setOpen(false);
  };

  return (
    <div>
      <Dialog
        disableBackdropClick
        open={true}
        onClose={handleClose}
        className="delete-modalTemp delete-modal"
        aria-labelledby="form-dialog-title"
        overlayStyle={{ backgroundColor: "transparent" }}
      >
        <DialogTitle id="form-dialog-title">
          <div className="delete-modalheading">
            <span>{"Navigate"}</span>
          </div>
        </DialogTitle>
        <DialogTitle id="form-dialog-title">
          <div className="delete-modalSubHeading">
            Are you sure you want to navigate back to
          </div>
        </DialogTitle>
        <DialogContent>
          <div className="delete-modalContent1">{props.cancelAction} ?</div>
        </DialogContent>
        <DialogActions className="delete-actionsTemp">
          <Button onClick={handleClose} color="primary" className="no-btn">
            No
          </Button>
          <Button
            onClick={() => handleClose("yes")}
            type="submit"
            className="btn btn-primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
