import React from 'react';
import {Box, Stepper, Step, StepLabel, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import "./Style.scss";

const StepperComp = (props) => {

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={props.activeStep}>
        {props.steps.map((label, index) => {
          return (
            <Step key={label} >
              <StepLabel >{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default StepperComp