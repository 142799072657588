import React, { useContext } from "react";

import "./Prompt.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// import "../../styles/components/Editor.scss";
// import "../../styles/components/EditorModal.scss";
// import ValueContext from "../../ContextAPi/appContext";
import Button from "@material-ui/core/Button";
// import { deleteBanners } from "../../services/apis";
// import ErrComponentToRender from "../../Uitls/apiErrorHandling";
import DeleteBox from "../../Images/Delete2.svg";

function TemplateDeleteModal({
  setShowDelete,
  showDelete,
  removeFile,
  file,
  fileName,
}) {
  const handleClose = () => {
    setShowDelete(false);
  };

  return (
    <Dialog
      disableBackdropClick
      open={showDelete}
      onClose={handleClose}
      className="delete-modalTemp delete-modal"
      aria-labelledby="form-dialog-title"
      overlayStyle={{ backgroundColor: "transparent" }}
    >
      <DialogTitle disableTypography className="dialogbox-header" id="form-dialog-title">
        <div className="delete-modalheading">
          <img className="deleteBoxTemp" src={DeleteBox} alt="deleteBox"></img>
          <span className="deleteBoxTextTemp">{"Delete"}</span>
        </div>
        <div className="dialog-close">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogTitle id="form-dialog-title">
        <div className="delete-modalSubHeading">
          Are you sure you want to
          <span className="DeleteOnly">{" Delete file?"}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="delete-modalContent">{fileName}</div>
      </DialogContent>
      <DialogActions className="delete-actionsTemp">
        <Button onClick={handleClose} color="primary" className="no-btn">
          CANCEL
        </Button>
        <Button
          onClick={() => {
            removeFile(file);
            handleClose();
          }}
          type="submit"
          className="btn btn-primary"
          autoFocus
        >
          PROCEED
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TemplateDeleteModal;
